import React from 'react';
import Loadable from 'react-loadable';
import {
  AccessibilityAuditComponentResultsSection,
  ClassnamePartial,
  CodeSnippet,
  FormSizePartial,
  IsDisabledPartial,
  IsReadOnlyPartial,
  IsRequiredPartial,
  PageNavigation,
  Paragraph,
  PlatformTab,
  PropList,
  PropListItem,
  QaIdPartial,
  Section,
} from '../../../../components';
import V5Notice from '../../v5-notice/V5Notice';
import styles from './style.module.scss';

const Text = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.Text;
    return <Component {...props} />;
  },
});

const Checkbox = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.Checkbox;
    return <Component {...props} />;
  },
});

const CheckboxParentGroup = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.CheckboxParentGroup;
    return <Component {...props} />;
  },
});

const ParentCheckbox = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.ParentCheckbox;
    return <Component {...props} />;
  },
});

const checkboxParentGroupSnippet = require('raw-loader!../../../../data/snippets/checkbox-parent-group.example');
const parentCheckboxSnippet = require('raw-loader!../../../../data/snippets/parent-checkbox.example');

const WebTab = () => {
  return (
    <PlatformTab>
      <V5Notice
        code
        componentName="Parent Checkbox"
        storybookUrl="https://uniform-web.storybook.hudltools.com/?path=/docs/forms-checkboxes--docs"
      />
      <PageNavigation links={['Parent Checkbox', 'Checkbox Parent Group']} />
      <Section title="Parent Checkbox">
        <Paragraph>
          The parent checkbox is best used in concert with the Checkbox Parent
          Group below.
        </Paragraph>

        <CodeSnippet
          scope={{
            React: React,
            Checkbox: Checkbox,
            ParentCheckbox: ParentCheckbox,
          }}
          code={parentCheckboxSnippet}
          platform="react"
          gitHubLink="forms/parent-checkbox"
          className={styles.parentCheckbox}
        />

        <Section title="Props">
          <PropList header="Visual">
            <PropListItem name="children" types={['ReactNode']}>
              <Text>
                Can be checkboxes or another level of parent checkboxes.
              </Text>
            </PropListItem>

            <ClassnamePartial componentName="parent checkbox" />

            <PropListItem name="isChecked" types={['boolean']}>
              <Text>Determines whether the parent checkbox is checked.</Text>
            </PropListItem>

            <IsDisabledPartial componentName="parent checkbox" />

            <IsReadOnlyPartial componentName="parent checkbox" />

            <PropListItem name="isPartiallyChecked" types={['boolean']}>
              <Text>
                Determines whether the checkbox is partially (indeterminate)
                checked.
              </Text>
            </PropListItem>

            <PropListItem name="label" types={['string']}>
              <Text>
                The label that appears to the right of the parent checkbox.
              </Text>
            </PropListItem>
          </PropList>

          <PropList header="Miscellaneous">
            <QaIdPartial componentName="parent checkbox" />
          </PropList>
        </Section>
      </Section>

      <Section title="Checkbox Parent Group">
        <Paragraph>
          The Checkbox Parent Group is a stateful component that automatically
          checks and partially checks any checkboxes in a selected tree.
        </Paragraph>

        <CodeSnippet
          scope={{
            React: React,
            Checkbox: Checkbox,
            ParentCheckbox: ParentCheckbox,
            CheckboxParentGroup: CheckboxParentGroup,
          }}
          code={checkboxParentGroupSnippet}
          platform="react"
          gitHubLink="forms/checkbox-parent-group"
          disableCodeEditing
          id="parent-checkbox-snippet"
        />

        <Section title="Props">
          <PropList header="Visual">
            <PropListItem name="children" types={['ReactNode']} isRequired>
              <Text>
                Can be parent checkboxes or checkboxes, but the first item must
                be a parent checkbox.
              </Text>
            </PropListItem>

            <FormSizePartial componentName="checkbox group" />

            <PropListItem name="header" types={['string']}>
              <Text>The text that appears above the group of checkboxes.</Text>
            </PropListItem>

            <IsDisabledPartial componentName="checkbox group" />

            <IsReadOnlyPartial componentName="checkbox group" />

            <IsRequiredPartial componentName="checkbox group" />

            <PropListItem name="valuesChecked" types={['string[]']}>
              <Text>
                The values checked in the group. Allows you to initially set
                which checkboxes are checked.
              </Text>
            </PropListItem>
          </PropList>

          <PropList header="Events">
            <PropListItem
              name="onChange"
              types={['(changedValue: any, valuesChecked: any) => void']}>
              <Text>
                Responds to the checkbox being checked or unchecked. The value
                of the selected checkbox is passed, as well as the currently
                checked values.
              </Text>
            </PropListItem>
          </PropList>

          <PropList header="Miscellaneous">
            <QaIdPartial componentName="checkbox parent group" />
          </PropList>
        </Section>

        <AccessibilityAuditComponentResultsSection
          platform="React"
          componentName="Forms / Checkbox Parent Group"
        />
      </Section>
    </PlatformTab>
  );
};

export default WebTab;
